import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import ArticleGist from "../components/ArticleGist";

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Blog" />
        {
          posts
            .filter(it => it.node.frontmatter.draft !== true)
            .map(({ node }) => ( <ArticleGist
              key={node.fields.slug}
              url={node.fields.slug}
              date={node.frontmatter.date}
              description={node.frontmatter.description || node.excerpt}
              image={node.frontmatter.coverImage.childImageSharp.fluid}
              title={node.frontmatter.title || node.fields.slug}
            />)
        )}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            draft
            coverImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
