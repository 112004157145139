import React from "react";
import './ArticleGist.scss';
import Img from "gatsby-image";
import {Link} from "gatsby";

export default function ArticleGist({ url, title, image, description, date }) {
  return (
    <article className={'gist'}>
      <Link to={url}>
        <Img fluid={image} />
      </Link>
      <div className={'data'}>
        <header>
          <h3>
            <Link to={url}>{title}</Link>
          </h3>
          <small>{date}</small>
        </header>
        <section>
          <p dangerouslySetInnerHTML={{ __html: description, }} />
        </section>
      </div>
    </article>
  )
}
